import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import * as numeral from 'numeral';

@Component({
  selector: 'app-fund-analysis',
  templateUrl: './fund-analysis.component.html',
  styleUrls: ['./fund-analysis.component.scss']
})
export class FundAnalysisComponent implements OnInit {
  selectedFunds: any[];
  fundSuggestions: string[];

  loadingFundTransactions: boolean = false;

  funds: any;
  fundsTransactions: any;

  apiHeaders: HttpHeaders;
  // TODO: create configuration for this
  apiUrlBase: string = 'https://statement-parser.blx.digital';

  constructor(private http: HttpClient) {
    this.apiHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', this.apiUrlBase)
      .set('Access-Control-Allow-Methods', 'GET, POST, PUT, OPTIONS')
      //.set('Upn', localStorage.getItem('adal.upn') ? localStorage.getItem('adal.upn') : '')
      .set('Token', localStorage.getItem('adal.idtoken') ? localStorage.getItem('adal.idtoken') : '');
  }

  ngOnInit() {
    this.http.get(`${this.apiUrlBase}/v1/route1/funds`, { headers: this.apiHeaders }).subscribe((results) => {
      this.funds = results;
    });
  }

  searchFunds(event) {
    if(this.funds) {
      this.fundSuggestions = this.funds.filter((fund) => {
        if(fund.title.toLowerCase().indexOf(event.query) > -1) {
          return true;
        }
        return false;
      })
    }
  }

  loadTransactionsForFunds() {
    this.fundsTransactions = [];
    const fundNumbers = this.selectedFunds.map((fund) => {
      return fund.number;
    });
    
    if(fundNumbers.length > 0) {
      this.loadingFundTransactions = true;
      this.http.get(`${this.apiUrlBase}/v1/route1/views/multi-fund-data/${fundNumbers.join(';')}?only_smifi_cash=true`, { headers: this.apiHeaders }).subscribe((results) => {
        this.loadingFundTransactions = false;
        this.fundsTransactions = results;
      });
    }
  }

  downloadExcel() {
    const fundNumbers = this.selectedFunds.map((fund) => {
      return fund.number;
    });
    
    if(fundNumbers.length > 0) {
      let url = `${this.apiUrlBase}/v1/route1/views/multi-fund-data/${fundNumbers.join(';')}?only_smifi_cash=true&excel_output=true`;
      this.http.get(url, {
          responseType: 'blob',
          headers: this.apiHeaders
        })
        .subscribe((response) => {
          console.log(response);
          const blob = new Blob([response], { type: response.type });
          const blobUrl = window.URL.createObjectURL(blob);
          // window.open(blobUrl, '_blank');
          // window.location.assign(blobUrl);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.target = '_blank';
          a.download = `CA Fund Transactions ${fundNumbers.join(' ')}.xlsx`;
          document.body.appendChild(a); // for FireFox
          a.click();
          document.body.removeChild(a); // for FireFox
          a.remove(); // remove the element
          window.URL.revokeObjectURL(blobUrl);
        },
        (error) => {
          window.alert('Error loading Excel: ' + error);
        });
    }
  }

  onTableChange = (a, b, c) => {

  }
}
