import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FundAnalysisComponent } from './pages/fund-analysis/fund-analysis.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';

const routes: Routes = [
  { path: '', redirectTo: '/funds', pathMatch: 'full'},
  { path: 'funds', component: FundAnalysisComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
