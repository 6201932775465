import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as numeral from 'numeral';
import * as _ from 'lodash';

@Component({
  selector: 'app-fund-tab-contents',
  templateUrl: './fund-tab-contents.component.html',
  styleUrls: ['./fund-tab-contents.component.scss']
})
export class FundTabContentsComponent implements OnInit, OnChanges {
  @Input() fundWithTransactions: any;

  tableTransactions: any;

  columnDefs = [
    { headerName: 'Section', field: 'section', width: 120 },
    { headerName: 'Date', field: 'date', width: 120 },
    { headerName: 'Entry', field: 'entry', width: 100 },
    { headerName: 'Debits', field: 'debits', valueFormatter: this.numberFormatter, type: 'numericColumn', width: 120  },
    { headerName: 'Interest', field: 'interest', valueFormatter: this.numberFormatter, type: 'numericColumn', width: 120  },
    { headerName: 'Credits', field: 'credits', valueFormatter: this.numberFormatter, type: 'numericColumn', width: 120  },
    { headerName: 'Running Balance', field: 'running_balance', valueFormatter: this.numberFormatter, type: 'numericColumn', width: 120 },
  ];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.fundWithTransactions) {
      let runningBalance = 0;

      if(this.fundWithTransactions.transactions[0]) {
        const t = this.fundWithTransactions.transactions[0];
        runningBalance = _.round(numeral(t.running_balance).value() - numeral(t.debits).value() + numeral(t.credits).value(), 2);
      }      
      
      this.tableTransactions = this.fundWithTransactions.transactions.map((t) => {
        runningBalance = runningBalance + numeral(t.debits).value() - numeral(t.credits).value();
        return {
          section: t.section,
          date: t.date,
          entry: t.entry,
          debits: t.debits,
          interest: t.interest,
          credits: t.credits,
          running_balance: runningBalance
        };
      });
    }
  }

  numberFormatter(params) {
    if(!params.value) {
      return '';
    }
    return numeral(params.value).format('0,000.00');
  }

}
