import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from 'primeng/tabview';

import { AgGridModule } from 'ag-grid-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { FundAnalysisComponent } from './pages/fund-analysis/fund-analysis.component';
import { FundTabContentsComponent } from './components/fund-tab-contents/fund-tab-contents.component';

import { AuthenticationGuard, MsAdalAngular6Module } from 'microsoft-adal-angular6';

@NgModule({
  declarations: [
    AppComponent,
    TitleBarComponent,
    FundAnalysisComponent,
    FundTabContentsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AutoCompleteModule,
    PanelModule,
    BrowserAnimationsModule,
    ButtonModule,
    ProgressSpinnerModule,
    TabViewModule,
    AgGridModule.withComponents([]),
    MsAdalAngular6Module.forRoot({
      tenant: 'orrick.onmicrosoft.com',
      clientId: 'c7ee7619-9f8f-4dbc-b528-a3f4791d31b1',
      redirectUri: window.location.href,
      navigateToLoginRequestUrl: false,
      cacheLocation: 'localStorage',
    })
  ],
  providers: [AuthenticationGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
